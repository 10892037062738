.editModal{
     position: fixed;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  background: #03000088;
  display: flex;
  z-index: 10;
}
.edit_content{
  width: 340px;
  justify-content: center;
   margin: auto;
   display: block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 16px;
  max-height: 400px;
  overflow: auto;
  height: 100%;
}
.edit_header{
    font: normal normal 300 16px/24px Poppins;
  letter-spacing: 0px;
  color: #181c32;
  padding-top: 30px;
  padding-left: 30px;
  width: 222px;
}
.edit_body{
     margin-top: 20px;
  margin-left: 5px;
}
.form_groupE {
  padding: 5px 0px 5px 0px;
}
input,
select:focus {
  outline: none;
}

.form_controlE {
  width: 250px;
  height: 50px;
  border: 1px solid #dddddd;
  border-radius: 7px;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #9e9e9e;
  padding-left: 10px;
  cursor: text;
}

.select_control {
  border: 1px solid #dddddd;
  border-radius: 7px;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  width: 390px;
  height: 50px;
  color: #9e9e9e;
}

#declared{
  margin-top: 5px;
}

.labelEdit{
    font: normal normal 600 14px/21px Poppins;
  color: #9e9e9e;
  padding-top: 50px;

}
.labelEditD{
    font: normal normal 600 14px/21px Poppins;
  color: #9e9e9e;
  padding-top: 50px;
}

.footerButtonsE {
  display: flex;
}
.saveEdit {
  padding-top: 20px;
  margin-top: -8px;

}

.cancelButton button {
  margin-top: 22px;
  padding-left: 10px;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #c52131;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}