.Modal {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  background: #03000088;
  display: flex;
  z-index: 10;
}

.modal_content {
  position: fixed;
  width: 440px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 16px;
  max-height: 470px;
  height: 100%;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal_header {
  font: normal normal 300 16px/24px Poppins;
  letter-spacing: 0px;
  color: #181c32;
  padding-top: 30px;
  padding-left: 30px;
  width: 242px;
}

.modal_body {
  margin-top: 18px;
  margin-left: 30px;
}
.form_group {
  padding: 5px 0px 5px 0px;
}
input,
select:focus {
  outline: none;
}

.form_control {
  width: 350px;
  height: 50px;
  border: 1px solid #dddddd;
  border-radius: 7px;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #9e9e9e;
  padding-left: 10px;

}
.form_control::placeholder {
  color: #9e9e9e;
}
.select_controlWeb{
   border: 1px solid #dddddd;
  border-radius: 7px;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  width: 365px;
  height: 50px;
  color: #9e9e9e;
}
.select_controlWeb option {
   color: black;
    outline-color: red;
font: normal normal 600 16px/90px Poppins;
}
.form_control input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.select_control {
  border: 1px solid #dddddd;
  border-radius: 7px;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  width: 370px;
  height: 50px;
  color: #9e9e9e;

}
.modal_body form .floating-label {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 90px;
  transition: 0.2s ease all;
    font: normal normal 600 14px/21px Poppins;
  color: #9e9e9e;
  z-index: 10;

}
.modal_body input:focus ~ .floating-label,
.modal_body input:not(:focus):valid ~ .floating-label {
  top: 60px;
}
.modal_body form .floating-labelA{
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 100px;
  transition: 0.2s ease all;
    font: normal normal 600 14px/21px Poppins;
  color: #9e9e9e;
  z-index: 10;

}
.modal_body input:focus ~ .floating-labelA,
 .modal_body input:not(:focus):valid ~ .floating-labelA {
  top: 125px;
}

.footerButtons {
  margin-top: 1px;
  display: flex;
}

.save button {
  margin-top: 10px;
  margin-bottom: 8px;
  height: 42px;
  cursor: pointer;
  padding-top: 2px;
  width: 94px;
  background: #c52131 0% 0% no-repeat padding-box;
  border-radius: 25px;
  border: none;
  font: normal normal 600 14px/21px Poppins;
  color: white;
}

.cancelButton  {
  margin-top: 2px;
  padding-left: 10px;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #c52131;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}
