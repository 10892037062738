.apiViewModal {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  background: #03000088;
  display: flex;
  z-index: 10;

  .apiModal_content {
    width: 500px;
    height: 230px;
    justify-content: center;
    margin: auto;
    display: block;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 16px;
  }

  .apiModal_title {
    text-align: center;
    font: normal normal 600 18px/30px Poppins,  sans-serif; 
    letter-spacing: 0px;
    color: #181c32;
    padding-top: 39px;
    padding-left: 30px;
    padding-right: 14px;
  }

  .apiModal_key {
    text-align: center;
    font: normal normal 600 18px/60px Poppins,  sans-serif;
    letter-spacing: 0px;
    color: #FFFFFF;
    background: #C52131;
    margin-top: 10px;
    
  }
  .apiModal_buttons {
    display: flex;
  }

  .noGenerate {
    padding-top: 33px;
    padding-left: 135px;
  }
  .yesG {
    font: normal normal 600 14px/21px Poppins,  sans-serif;
    letter-spacing: 0px;
    border: none;
    cursor: pointer;
    padding-top: 14px;
    padding-left: 25px;
  }
  .yesGenerate {
    height: 44px;
    width: 72px;
    border-radius: 25px;
    margin-top: 32px;
    margin-left: 12px;
  }
}