.Login-component {
  background: url('../../assets/images/new-bg.png') no-repeat;
  background-size:cover;
  background-position: left;
  width: 100vw;
  height: 100vh;
  .login-box {
    max-width: 443px;
    max-height: 345px;
    width: 100%;
    height: 100%;
    overflow-y:auto;
    border-radius: 25px;
    background-color: white;
    padding: 40px 30px ;
    box-sizing: border-box;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    .logoLogin {
      height: 60px;
      margin-bottom: 6px;
      margin-top: -24px;
      margin-left: -10px;
    }
    .login-text {
      font: normal normal 600 18px/21px Poppins;
      letter-spacing: 0;
      color: #32363a;
      margin-bottom: 1px;
    }
    .vendor-hint {
      font: normal normal medium 14px/21px Poppins;
      letter-spacing: 0;
      color: #9e9e9e;
      margin-bottom: 33px;
    }
  }
}
.login_content {
  position: fixed;
  width: 440px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 16px;
  max-height: 200px;
  height: 100%;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.change_content {
  position: fixed;
  width: 440px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 16px;
  max-height: 300px;
  height: 100%;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
}
.form_groupL {
  padding: 5px 10px 5px 0px;
}
.form_controlL {
  width: 350px;
  height: 50px;
  border: 1px solid #dddddd;
  border-radius: 7px;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #9e9e9e;
  padding-left: 10px;

}
.form_controlL::placeholder {
  color: #9e9e9e;
}
.closeLogin {
  margin-top: 20px;
  margin-left: 28px;
}
#closeL {
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #c52131;
  background-color: #ffffff;
  border: none;
  cursor: pointer;

}
.forgotPassword {
  margin-top: 4px;
}
#forgot {
  font: normal normal 600 14px/10px Poppins;
  letter-spacing: 0px;
  color: #c52131;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}
.forgot_content{
   width: 440px;
  height: 201px;
  justify-content: center;
  margin: auto;
  display: block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 16px;
}
.forgotModal_title{
   text-align: center;
  font: normal normal 600 16px/30px Poppins;
  letter-spacing: 0px;
  color: #181c32;
  padding-top: 39px;
  padding-left: 10px;
  padding-right: 14px;
}
.logIn button{
   margin-top: 15px;
  margin-bottom: -10px;
  height: 42px;
  cursor: pointer;
  padding-top: 0px;
  width: 94px;
  background: #c52131 0% 0% no-repeat padding-box;
  border-radius: 25px;
  border: none;
  font: normal normal 600 14px/21px Poppins;
  color: white;
}
.invalid_content{
    width: 440px;
  height: 200px;
  justify-content: center;
  margin: auto;
  display: block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 16px;
}
.invalid_title{
   text-align: center;
  font: normal normal 600 18px/30px Poppins;
  letter-spacing: 0px;
  color: #181c32;
  padding-top: 39px;
  padding-left: 10px;
  padding-right: 14px;
}
.retry{
  margin-left: 170px;
  margin-top: 40px;
}
@media only screen and (min-width: 1200px) {
  .Login-component .login-box {
    left: 90%;
    transform: translate(-90%, -50%);
  }

}