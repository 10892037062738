header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 81px;
  background: #c52131 0% 0% no-repeat padding-box;
  border-bottom: 2px groove;
  border-bottom-color: rgba(255, 255, 255, 0.26);
  opacity: 1;
  z-index: 10;
}

.userInfo {
  position: relative;
  display: inline-block;
  float: right;
  margin-top: 25px;
  margin-right: 100px;

  text-align: center;
  cursor: pointer;

  .a {
    display: flex;
    align-items: center;
  }

  .userIcon {
    margin-right: -12px;
  }

  .userEmail {
    font: normal normal medium 16px/25px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
  }

  .down {
    margin-top: 8px;
    width: 8px;
    height: 7px;
    color: #ffffff;
    padding-left: 4px;
  }

  .up {
    margin-top: 8px;
    width: 8px;
    height: 7px;
    color: #ffffff;
    padding-left: 4px;
  }

  .dropdownBox {
    // position: relative;
    // left:80px;
    // margin-top: 3px;
    float: right;
    padding-right: 2px;
    padding-left: -8px;
    text-align: center;
    justify-content: center;
    width: 150px;
    height: 100px;
    display: grid;
    grid-template-columns: auto auto auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000001a;
    border-radius: 10px;
    z-index: 10;
    gap: 14px;
  }

  .vertical {
    border: 0.01px solid  #EFF2F5 ;
min-height:121%;
      position: absolute;
    top: 32px;
    left: 49.5%;
  }

  .accountInfo {
    display: grid;
    font: normal normal 600 13px/20px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    align-items: center;
  }

  .accountIcon {
    margin-top: 12px;
  }

  .accountText {
    margin-top: -30px;
    text-align: left;
  }

  .logOut {
    display: grid;
    font: normal normal 600 13px/20px Poppins;
    letter-spacing: 0px;
    color: #181c32;
    margin-left: 10px;
    margin-top: 5px;
  }

  .logOut .logoutIcon {
    margin-top: 20px;
  }

  .logOutText {
    text-align: left;
        margin-top: -17px;

  }

  .accountInfo .tooltipt {
    visibility: hidden;
    width: 94px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding-left: 10px;
    position: absolute;
    z-index: 1;
  }

  .accountInfo:hover .tooltipt {
    visibility: visible;
  }
}

.red_bg {
  position: absolute;
  width: 100%;
  height: 285px;
  background: #c52131 0% 0% no-repeat padding-box;
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .red_bg {
    max-width: 100%;
  }

  header {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1920) {
  header {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
  }

  .red_bg {
    max-width: 100%;
    width: 100%;
  }
}