.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 14px;
  border: 2px solid white;
  border-radius: 34px;
  cursor: pointer;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transition: 0.2s;
  border-radius: 34px;
}

.slider::before {
  position: absolute;
  content: '';
  height: 6px;
  width: 6px;
  right: 22px;
  bottom: 0px;
  transition: 0.5s;
  border-radius: 50px;
  border: 2px solid white;
  margin: 2px -3px;
}

input:checked+.slider:before {
  transform: translateX(16px);
}