.customer-account{
  width: 560;
  max-height: 800px;

  .modal_header{
    width: calc(100% - 60px);
    padding: 30px 30px;
    padding-bottom: 0px;
  }

  .modal_body{
    height: calc(100% - 140px);
    width: calc(100% - 70px);
    padding: 30px;
    padding-top: 10px;
    margin: 0;

    .ag-cell{
      width: calc(100% - 38px)!important;
    }
  }

  .footerButtons{
    margin-top: 1px;
    display: flex;
    justify-content: center;
  }
}
