@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

* {
  margin: 0;
  padding: 0;
}

.d-flex{
  display: flex;
}

.justify-content-between{
  justify-content: space-between;
}

@media only screen and (max-width: 1920px) {
  * {
    /* max-width: 100%; */
    max-height: 100%;
  }
}

.ag-header-cell {
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #181c32;
  opacity: 1;
}

.ag-center-cols-container{
  width: 100%;
}
