.loadingScreen {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  background: #35353533;
  display: flex;
  z-index: 10;
}

.loading {
  width: 80px;
  background: transparent;
  justify-content: center;
  margin: auto;
  display: block;
}