body {
  background: #fffafb 0% 0% no-repeat padding-box;
}
.webhookPageContent {
  float: left;
  width: 100%;
  height: 100%;
}

.webhookCard {
  position: absolute;
  top: 111px;
  left: 280px;
  margin-left: 0px;
  width: 78%;
  min-height: 1134px;
  height: 80%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000005;
  border-radius: 10px;
  opacity: 1;
  z-index: 1;
}
.webhookCardBody {
  margin-top: 53px;
  margin-left: 29px;
  width: 99%;
}
.webhookLabelText {
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #181c32;
  display: inline-block;
}

.modal_contentWeb{
position: fixed;
  width: 440px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 16px;
  max-height: 440px;
  height: 100%;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.addSubscription {
  // sdisplay: inline-block;
  margin-right:110px;
  float: right;
}

.select_controlWebEdit{
  border: 1px solid #dddddd;
  border-radius: 7px;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  width: 264px;
  height: 50px;
  color: #9e9e9e;
}
.select_controlWebEdit option {
  color: black;
   outline-color: red;
font: normal normal 600 16px/90px Poppins;
}

.ag-theme-balham {
  margin-top: 30px;
  width: 100%;
}
.ag-theme-balham .ag-header-cell-label {
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #181c32;
  opacity: 1;
}
.ag-theme-balham .ag-header-cell {
  background-color: white;
}
.ag-theme-balham .ag-paging-panel {
  position: inherit;
  color: #a1a5b7;
  margin-top: 20px;
  font: normal normal 600 12px/20px Poppins;
}
.ag-paging-button {
  color: #a1a5b7;
}

.ag-paging-row-summary-panel {
  text-align: left;
  padding-right: 450px;
  letter-spacing: 0px;
}

.actionIconsWeb button {
  width: 30px;
  height: 30px;
  border: white;
  border-radius: 3px;
  cursor: pointer;
}
.editWeb {
  width: 30px;
  height: 30px;
  background-color: white;
}
#edit path{
     fill: #f64e60;
}
.deleteWeb {
  width: 30px;
  height: 30px;
  background: #fbedef 0% 0% no-repeat padding-box;
  margin-left: 10px;
}

#delete path{
     fill: #f64e60;
}

.actionIconsWeb :hover {
  background-color: #c52131;
  #delete path{
    fill: white;
  }
  #edit path {
    fill: white;
  }
}
.errorMessage {
  color: #c52131;
  font: normal normal normal 14px/21px Poppins;
  margin-left: 4px;
}
.mainPa {
  margin: -6% 80%;
  padding: 5%;
  font: normal normal normal 14px/21px Poppins;
  font-size: 20px;
  color: #a1a5b7;
}

.container {
  border-bottom: #fff 2px ridge;
}
.disabledPagination{
  color: #e3e4e9;
}
.pagination {
  margin-top: 15px;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination>.active>a {
  background: #FBEDEF 0% 0% no-repeat padding-box;
  color: #c52131;
  width: 24px;
  height: 24px;
  border-radius: 3px;
}
.pagination>li>a {
  border: 1px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

@media only screen and (max-width: 1920) {
   body {
    max-width: 100%;
    max-height: 100%;
  }
.webhookPageContent{
   max-width: 100%;
    width: 100%;
}
.webhookCard{
   max-height: 100vh;
    height: 80%;
    max-width: 100%;
    width: 100%;
}
.webhookCardBody{
  max-width: 100%;
    width: 99%;
}
}

@media only screen and (min-width: 768px) {
  .webhookCard {
    max-height: 100vh;
    height: 80%;
    min-width: 1029px;
    width: 100%;
    max-width: calc(100% - 310px);
  }
}
