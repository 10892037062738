.deleteModal {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  background: #03000088;
  display: flex;
  z-index: 10;
}
.deleteModal_content {
  width: 440px;
  height: 221px;
  justify-content: center;
  margin: auto;
  display: block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 16px;
}

.deleteModal_title {
  text-align: center;
  font: normal normal 600 18px/30px Poppins;
  letter-spacing: 0px;
  color: #181c32;
  padding-top: 39px;
  padding-left: 30px;
  padding-right: 14px;
}

.deleteModal_buttons {
  display: flex;
}
.no {
  padding-top: 32px;
  padding-left: 135px;
}

.yesButton {
  font: normal normal 600 16px/21px Poppins;
  letter-spacing: 0px;
  border: none;
  cursor: pointer;
  padding-top: 10px;
  padding-left: 26px;
}

.yes {
  height: 40px;
  width: 32px;
  border-radius: 25px;
  margin-top: 32px;
  margin-left: 18px;
}
