@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.nav-menu {
  background: #58585a 0% 0% no-repeat padding-box;
  height: 850px;
  width: 250px;
  top: 0px;
  z-index: 10;
  opacity: 1;
  display: flex;
  position: fixed;
  justify-content: center;
  transition: 150ms;
  float: left;
}

.nav-menu-active {
  display: flex;
  justify-content: flex-start;
  position: fixed;
  height: 850px;
  top: 0px;
  background: #58585a 0% 0% no-repeat padding-box;
  width: 70px;
  z-index: 10;
  list-style: none;
  transition: 250ms;

  .toggle {
    position: absolute;
    top: 32px;
    left: 100px;
    cursor: pointer;
    opacity: 1;
  }

  .nav-text a #title {
    visibility: hidden;
  }

  .nav-text a #iconN {
    position: absolute;
  }

  .nav-menu-items {
    margin-left: -8px;
  }

  .nav-menu-items a:hover .tooltiptext {
    visibility: hidden;
  }
}

.toggle {
  position: absolute;
  top: 32px;
  left: 280px;
  cursor: pointer;
  opacity: 1;
  transition: 250ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  list-style-type: none;
  height: 72px;
  text-align: start;
}

.nav-text a {
  display: flex;
  align-items: center;
  text-decoration: none;
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0px;
  opacity: 1;
  color: #b7a1a1;

}

.disabled a {
  cursor: not-allowed;
}

.nav-text a:target {
  color: white;
}

.nav-text a #iconN {
  margin-top: 5px;
  margin-left: 21px;
}

// .nav-text #iconN svg :active{
//   fill: white;
//   color:white;
// }
.activePage path{
  fill: white;
  color: white;
}
.activePage rect{
  fill: white;
  color: white;
}
.logo {
  position: fixed;
  margin-top: 2rem;
  margin-left: -9rem;
  cursor: pointer;
  width: 40px;
  height: 50px;
}

.logoL {
  position: fixed;
  margin-top: 2rem;
  margin-left: 20px;
  border-radius: 2px;
  opacity: 1;
  overflow: hidden;
  cursor: pointer;
width: 30px;
height: 30px;
}

.nav-menu-items {
  padding-top: 4.4rem;
  padding-right: 1rem;
  width: 100%;
  margin-top: 1.5rem;

}

.nav-menu-items ul {
  list-style: none;
  padding-left: 0.5rem;
}

.nav-menu-items .tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding-left: 20px;
  position: absolute;
  margin-left: 60px;
  margin-bottom: 50px;
}

.nav-menu-items a:hover .tooltiptext {
  visibility: visible;
}

span {
  margin-left: 20px;
}

.activeLink {
  position: absolute;
  left: 0;
  width: 6px;
  height: 40px;
  background: #c52131 0% 0% no-repeat padding-box;
  border-radius: 0px 3px 3px 0px;
  animation-duration: 300ms;
  transition: 350ms;
}

@media only screen and (min-width: 768px) {
  .nav-menu {

    max-height: 100vh;
    height: 100%;
  }

  .nav-menu-active {
    max-height: 100vh;
    height: 100%;
  }
}

@media only screen and (max-width: 1920px) {
  .nav-menu {
    max-height: 100vh;
    height: 100%;
  }

  .nav-menu-active {
    max-height: 100vh;
    height: 100%;
  }
}
